import React from 'react';
import * as Sentry from '@sentry/react';
import { isAndroid, isIOS } from 'react-device-detect';
import { createRoot, RootOptions } from 'react-dom/client';
import { APP_GOOGLE_URL, APP_IOS_URL } from './config';
import Loading from './views/Components/Loading';
import App from './App';
import register from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://b6774a05711b45bdb46a848bf93a9598@sentry.kivi.bz.it/23',
  integrations: [
    // See docs for support of different versions of variation of react router

    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
if (window.location.pathname.indexOf('/app/get') !== -1 && isAndroid) {
  window.location.replace(APP_GOOGLE_URL);
} else if (window.location.pathname.indexOf('/app/get') !== -1 && isIOS) {
  window.location.replace(APP_IOS_URL);
} else {
  const container = document.getElementById('root');
  if (container) {
    const root = createRoot(container, {
      onRecoverableError: Sentry.reactErrorHandler((error, errorInfo) => {
        console.warn('Uncaught error', error, errorInfo.componentStack);
      }) as RootOptions['onRecoverableError'],
    });
    root.render(
      <React.StrictMode>
        <React.Suspense fallback={<Loading />}>
          <App />
        </React.Suspense>
      </React.StrictMode>,
    );
  }
}

register();
reportWebVitals();
