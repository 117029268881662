import * as React from 'react';
import { ComponentType, Context, lazy } from 'react';
import * as Sentry from '@sentry/react';
import aes from 'crypto-js/aes';
import hex from 'crypto-js/enc-hex';
import utf8 from 'crypto-js/enc-utf8';
import { LocaleConfig, translate, TranslatorKeys } from './Translator';
import {
  BakeryUser,
  CollectionType,
  csvImportImage,
  DashboardDataItem,
  DashboardDataItemLarge,
  Holiday,
  IDType,
  JustID,
  Keyword,
  LanguageISO,
  MediaFormats,
  Pages,
  StrapiMedia,
  Translation,
  User,
  UserEssential,
} from './types';
import {
  baseURL,
  DAY_IN_MS,
  MESSAGE_TYPE,
  UserContextTypeLoggedIn,
} from './index';

export function errorLogging(
  // eslint-disable-next-line @typescript-eslint/ban-types
  error: object | string,
  severity = 'warning',
): void {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.trace(error);
  } else if (severity === 'error') {
    Sentry.captureMessage(
      typeof error === 'string' ? error : JSON.stringify(error),
    );
  }
}

/**
 *
 * @param {Translation} translations
 * @param {string} lang
 * @param {number} maxLen
 */
export function showTranslated(
  translations: Translation[],
  lang: string,
  maxLen = 100000,
): string {
  for (const translation of translations) {
    if (translation.language.short === lang) {
      if (translation.translation.length > maxLen) {
        return `${translation.translation.slice(0, maxLen)}...`;
      }
      return translation.translation;
    }
  }
  if (translations[0].translation.length > maxLen) {
    return `${translations[0].translation.slice(0, maxLen)}...`;
  }
  return translations[0].translation;
}

/**
 *
 * @param {Date} date
 * @param {string} language
 *
 * @return {string}
 */
export function formatDeadline(date: Date, language: LanguageISO): string {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  const hours = Math.floor(diff / 3.6e6);
  const minutes = Math.floor((diff % 3.6e6) / 6e4);
  let hourString = `${hours} ${translate(
    hours === 1 ? 'hour' : 'hours',
    language,
  )}`;
  let minuteString = `${minutes} ${translate(
    hours === 1 ? 'minute' : 'minutes',
    language,
  )}`;
  if (hours === 0) {
    hourString = '';
  }
  if (minutes === 0) {
    minuteString = '';
  }

  return translate('deadline', language, {
    '[deadline]': `${hourString} ${minuteString}`,
  });
}

export function formatGraphqlDate(
  date: Date | string,
  long = false,
  forInput = false,
  onlyDateLong = false,
): string {
  const d = new Date(date);
  if (typeof d === 'undefined') {
    return '';
  }
  let options: Intl.DateTimeFormatOptions;
  if (long) {
    options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
  } else if (onlyDateLong) {
    options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      weekday: 'short',
    };
  } else {
    options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  }
  if (forInput) {
    if (long) {
      return d.toISOString();
    }
    return d.toISOString().split('T')[0];
  }
  return d.toLocaleDateString('de-DE', options);
}

export function formatQuarter(date: Date | string): string {
  const d = new Date(date);
  if (typeof d === 'undefined') {
    return '';
  }
  const m = d.getMonth();
  if (m < 3) {
    return `Q1 ${d.getFullYear()}`;
  }
  if (m < 6) {
    return `Q2 ${d.getFullYear()}`;
  }
  if (m < 9) {
    return `Q3 ${d.getFullYear()}`;
  }
  return `Q4 ${d.getFullYear()}`;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function unfreeze<T extends object | unknown[]>(o: T): T {
  if (o instanceof Array) {
    const oo: unknown[] = [];
    o.forEach((v) => {
      oo.push(v);
    });
    return oo as T;
  }

  return { ...o };
}

function isSelectElement(
  field: HTMLInputElement | HTMLSelectElement,
): field is HTMLSelectElement {
  return field.nodeName.toUpperCase() === 'SELECT';
}

function isInputElement(
  field: HTMLInputElement | HTMLSelectElement,
): field is HTMLInputElement {
  return (
    (field.nodeName.toUpperCase() === 'INPUT' ||
      field.nodeName.toUpperCase() === 'TEXTAREA') &&
    !field.disabled &&
    field.type !== 'file' &&
    field.type !== 'reset' &&
    field.type !== 'submit' &&
    field.type !== 'button'
  );
}

export function serialize(form: HTMLFormElement): {
  [T in string]: string | boolean | number | string[];
} {
  let field: HTMLInputElement | HTMLSelectElement;
  const s: {
    [T in string]: string | boolean | number | string[];
  } = {};
  if (typeof form === 'object' && form.nodeName.toUpperCase() === 'FORM') {
    const len = form.elements.length;
    for (let i = 0; i < len; i++) {
      field = form.elements[i] as HTMLInputElement | HTMLSelectElement;
      const key = encodeURIComponent(field.name);
      if (isInputElement(field)) {
        if (field.type === 'checkbox') {
          s[key] = field.checked;
        } else if (field.type === 'radio' && field.checked) {
          s[key] = field.value;
        } else {
          s[key] = field.value;
        }
      } else if (isSelectElement(field)) {
        if (field.multiple) {
          s[key] = [];
        }
        for (let j = field.options.length - 1; j >= 0; j--) {
          if (field.options[j].selected) {
            if (field.multiple) {
              (s[key] as string[]).push(field.options[j].value);
            } else {
              s[key] = field.options[j].value;
            }
          }
        }
      }
    }
  }
  return s;
}

export function getHolidayDatesInRange(
  holidays: Holiday[],
  from: Date,
  to: Date,
): Date[] {
  const dates: Date[] = [];
  let running = from.getTime();
  const end = to.getTime();
  while (running <= end) {
    for (const h of holidays) {
      const hFrom = new Date(h.from).getTime();
      const hTo = new Date(h.from).getTime();
      if (running >= hFrom && running <= hTo) {
        dates.push(new Date(running));
      }
    }
    running += DAY_IN_MS;
  }
  return dates;
}

/**
 *
 * @param pw1
 * @param language
 */
export function checkPwValid<T extends LanguageISO | null>(
  pw1: string,
  language: T,
): T extends LanguageISO ? string[] : boolean {
  const missing: string[] = [];

  if (pw1.length < 8) {
    if (language === null) {
      return false as T extends LanguageISO ? string[] : boolean;
    }
    missing.push(translate('tooShort', language));
  }
  if (language === null) {
    return true as T extends LanguageISO ? string[] : boolean;
  }
  return missing as T extends LanguageISO ? string[] : boolean;

  /*
  if (pw1.match(/[0-9]/) === null) {
    if (language === null) {
      return false as T extends LanguageISO ? string[] : boolean;
    }
    missing.push(translate('numberMissing', language));
  }
  if (pw1.match(/[a-z]/) === null) {
    if (language === null) {
      return false as T extends LanguageISO ? string[] : boolean;
    }
    missing.push(translate('lowercaseMissing', language));
  }
  if (pw1.match(/[A-Z]/) === null) {
    if (language === null) {
      return false as T extends LanguageISO ? string[] : boolean;
    }
    missing.push(translate('uppercaseMissing', language));
  }
  if (pw1.match(/[^a-zA-Z0-9]/) === null) {
    if (language === null) {
      return false as T extends LanguageISO ? string[] : boolean;
    }
    missing.push(translate('specialMissing', language));
  }
  if (language === null) {
    return true as T extends LanguageISO ? string[] : boolean;
  }
  return missing as T extends LanguageISO ? string[] : boolean;
  */
}

export declare type MESSAGE_STATE = {
  message: string | string[];
  messageType: MESSAGE_TYPE;
  loading?: boolean;
};

export declare type PasswordInputState = MESSAGE_STATE & {
  pwValid: boolean;
  pwRepeatValid: boolean;
  passwordMsg: JSX.Element | undefined;
};

export class PasswordComponent<
  U,
  T extends PasswordInputState,
> extends React.Component<U, T> {
  declare context: React.ContextType<Context<UserContextTypeLoggedIn>>;

  passwordInput: HTMLInputElement | null = null;

  passwordRepeatInput: HTMLInputElement | null = null;

  assignPWInputs(): void {
    if (this.passwordInput == null) {
      this.passwordInput = document.getElementById(
        'password',
      ) as HTMLInputElement | null;
      if (this.passwordInput == null) {
        this.passwordInput = document.getElementById(
          'newPassword',
        ) as HTMLInputElement | null;
      }
    }
    if (this.passwordRepeatInput == null) {
      this.passwordRepeatInput = document.getElementById(
        'passwordRepeat',
      ) as HTMLInputElement | null;
    }
  }
}

export function checkPWEqualAndValid<U, T extends PasswordInputState>(
  this: PasswordComponent<U, T>,
): boolean {
  this.assignPWInputs();
  const pw = this.passwordInput ? this.passwordInput.value : ' ';
  const pwRep = this.passwordRepeatInput ? this.passwordRepeatInput.value : '';
  const { language } = this.context;
  const message: string[] = checkPwValid(pw, language as LanguageISO);
  if (pw.length === 0 || pw !== pwRep) {
    message.push(translate('passwordNotEqual', language));
    this.setState({ pwRepeatValid: false });
  } else {
    this.setState({ pwRepeatValid: true });
  }
  if (message.length === 0) {
    this.setState({ passwordMsg: undefined });
    this.setState({ pwValid: true });
  } else {
    this.setState({ pwValid: message.length === 1 && pw !== pwRep });
    this.setState({
      passwordMsg: (
        <>
          {message.map((msg, index) => (
            <span key={`msg-${index.toString()}`}>
              {msg}
              <br />
            </span>
          ))}
        </>
      ),
    });
  }
  return false;
}

export function generatePW(length = 12, bigCharset = true): string {
  let charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890123456789';
  if (bigCharset) {
    charset += '-_#+*!§$%&/()=?ß{[]}';
  }
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  if (!bigCharset) {
    return retVal;
  }
  if (checkPwValid(retVal, null)) {
    return retVal;
  }
  return generatePW(length, bigCharset);
}

export function fillPasswordFields<U, T extends PasswordInputState>(
  this: PasswordComponent<U, T>,
): void {
  this.assignPWInputs();
  const newPW = generatePW(12, false);
  if (this.passwordInput && this.passwordRepeatInput) {
    this.passwordRepeatInput.value = newPW;
    this.passwordInput.value = newPW;
  }
  this.setState({ pwValid: true, pwRepeatValid: true });
  showPasswordFields.call(this);
}

export function showPasswordFields<U, T extends PasswordInputState>(
  this: PasswordComponent<U, T>,
): void {
  this.assignPWInputs();
  if (this.passwordInput && this.passwordRepeatInput) {
    this.passwordRepeatInput.setAttribute('type', 'text');
    this.passwordInput.setAttribute('type', 'text');
  }
}

export function hidePasswordFields<U, T extends PasswordInputState>(
  this: PasswordComponent<U, T>,
): void {
  this.assignPWInputs();
  if (this.passwordInput && this.passwordRepeatInput) {
    this.passwordRepeatInput.setAttribute('type', 'password');
    this.passwordInput.setAttribute('type', 'password');
  }
}

/**
 *
 * @param page
 * @param language
 */
export function getHeaderInfos(
  page: Pages,
  language: LanguageISO,
): { title: string; desc: string } {
  return {
    title:
      translate(`page${page}Title` as TranslatorKeys, language) +
      translate('pageTitle', language),
    desc: translate(`page${page}Desc` as TranslatorKeys, language),
  };
}

export enum STEP {
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

export function msgTypeCss(type: MESSAGE_TYPE): string {
  switch (type) {
    case MESSAGE_TYPE.DANGER:
      return 'danger';
    case MESSAGE_TYPE.MUTED:
      return 'muted';
    case MESSAGE_TYPE.SUCCESS:
      return 'success';
    case MESSAGE_TYPE.INFO:
    default:
      return 'info';
  }
}

function setOperation<T extends { [U in string]: string }>(
  this: React.Component<unknown, MESSAGE_STATE & unknown>,
  messageKey: TranslatorKeys | TranslatorKeys[],
  messageType: MESSAGE_TYPE,
  params?: T,
): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const c: React.ContextType<Context<UserContextTypeLoggedIn>> = this.context;
  if (typeof this.state.loading !== 'undefined') {
    this.setState({
      loading: false,
      message: Array.isArray(messageKey)
        ? messageKey.map((m) => translate(m, c.language, params))
        : translate(messageKey, c.language, params),
      messageType,
    });
  } else {
    this.setState({
      message: Array.isArray(messageKey)
        ? messageKey.map((m) => translate(m, c.language, params))
        : translate(messageKey, c.language, params),
      messageType,
    });
  }
}

export function setOperationFailed<T extends { [U in string]: string }>(
  this: React.Component<unknown, MESSAGE_STATE & unknown>,
  messageKey: TranslatorKeys | TranslatorKeys[],
  params?: T,
): void {
  setOperation.call(this, messageKey, MESSAGE_TYPE.DANGER, params);
}

export function setOperationSuccess<T extends { [U in string]: string }>(
  this: React.Component<unknown, MESSAGE_STATE & unknown>,
  messageKey: TranslatorKeys | TranslatorKeys[],
  params?: T,
): void {
  setOperation.call(this, messageKey, MESSAGE_TYPE.SUCCESS, params);
}

export function setOperationMuted<T extends { [U in string]: string }>(
  this: React.Component<unknown, MESSAGE_STATE & unknown>,
  messageKey: TranslatorKeys | TranslatorKeys[],
  params?: T,
): void {
  setOperation.call(this, messageKey, MESSAGE_TYPE.MUTED, params);
}

export function setOperationInfo<T extends { [U in string]: string }>(
  this: React.Component<unknown, MESSAGE_STATE & unknown>,
  messageKey: TranslatorKeys | TranslatorKeys[],
  params?: T,
): void {
  setOperation.call(this, messageKey, MESSAGE_TYPE.INFO, params);
}

export function extractLabels(
  item: DashboardDataItem | DashboardDataItemLarge,
  language: string,
  step: STEP,
): string {
  let options;
  switch (step) {
    case STEP.DAY:
    case STEP.WEEK:
      options = {
        month: 'long',
        day: '2-digit',
      };
      break;
    case STEP.MONTH:
    case STEP.YEAR:
    default:
      options = {
        month: 'long',
        year: 'numeric',
      };
      break;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return item.date.toLocaleDateString(language, options);
}

export function formatNewlineAndLinks(text: string): JSX.Element[] {
  const first = text.slice(0, 5);
  const links = text.matchAll(/\[link [a-zA-Z0-9-]+ [a-zA-Z0-9/]+]/g);
  const formattedLinks: JSX.Element[] = [];
  for (let elem = links.next(); !elem.done; elem = links.next()) {
    if (elem.value && elem.value.length) {
      const val = elem.value[0];
      const desc = val.split(' ')[1];
      let target = val.split(' ')[2];
      target = target.substr(0, target.length - 1);
      text = text.replace(val, '[#href]');
      formattedLinks.push(<a href={target}>{desc}</a>);
    }
  }

  return text.split('\n').map((splitter, index) => {
    const linkSplitted = splitter.split('[#href]');
    const key = first + splitter.slice(0, 5) + index.toString();
    return (
      <React.Fragment key={key}>
        {linkSplitted.map((splitter2, index2) => (
          <React.Fragment
            key={`${key}link-${splitter2.slice(0, 5)}-${index.toString()}`}>
            {splitter2}
            {linkSplitted.length !== 1 &&
              index2 !== linkSplitted.length - 1 &&
              formattedLinks.shift()}
          </React.Fragment>
        ))}
        <br />
      </React.Fragment>
    );
  });
}

export function fileEnding(filename: string): string {
  const ending = filename.split('.').pop();
  return ending || '';
}

export function showImage(image: csvImportImage): string {
  return URL.createObjectURL(
    new Blob([image.data], {
      type: `image/${fileEnding(image.path).toLowerCase()}`,
    }),
  );
}

export function arrayMoveIdx<T>(
  arr: T[],
  fromIndex: number,
  toIndex: number,
): false | [number, number] {
  if (
    fromIndex === -1 ||
    fromIndex >= arr.length ||
    toIndex === -1 ||
    toIndex >= arr.length
  ) {
    return false;
  }
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return [
    fromIndex < toIndex ? fromIndex : toIndex,
    fromIndex < toIndex ? toIndex : fromIndex,
  ];
}

export function arrayMoveElem<T>(
  arr: T[],
  from: T,
  to: T,
): false | [number, number] {
  const fromIndex = arr.indexOf(from);
  const toIndex = arr.indexOf(to);
  return arrayMoveIdx(arr, fromIndex, toIndex);
}

export function prepareGuestUsername(username: string): string {
  return username + generatePW(32, false);
}

export function showUsername(user: User | UserEssential): string {
  if (!user.is_host && !user.is_bakery && user.username.length > 32) {
    return user.username.slice(0, -32);
  }
  return user.username;
}

export function showName(user: User | UserEssential): string {
  if (!user.is_host && !user.is_bakery && user.username.length > 32) {
    return user.username.slice(0, -32);
  }
  let name = user.username;
  if (user.userdetail && typeof user.userdetail === 'object') {
    name += ` (${
      user.userdetail.full_name
        ? user.userdetail.full_name
        : user.userdetail.company_name
    })`;
  }
  return name;
}

const slowvitaPW = '82c2f0bf35f1ac15f936b0862e5c5081';
const slowvitaSalt = '8f3bd77407530fe7f20fa3d10f355894';

export function encrypt(message: string, salt: string = slowvitaSalt): string {
  const key = hex.parse(salt);
  const iv = hex.parse(slowvitaPW);
  const enc = aes.encrypt(message, key, { iv });
  return enc.ciphertext.toString(hex);
}

export function decrypt(hash: string, salt: string = slowvitaSalt): string {
  const key = hex.parse(salt);
  const iv = hex.parse(slowvitaPW);
  const dec = aes.decrypt(hash, key, { iv });
  return dec.toString(utf8);
}

// eslint-disable-next-line @typescript-eslint/ban-types
function isFunction(item: unknown): item is Function {
  return typeof item === 'function';
}

export function autobind<T extends React.Component>(
  instance: T,
  functionNames: (keyof T)[],
): void {
  functionNames.forEach((func) => {
    const f = instance[func];
    if (isFunction(f)) {
      instance[func] = f.bind(instance);
    }
  });
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>;

export function lazyWithRetry(
  component: () => ComponentPromise,
  retries?: number,
  interval?: number,
): React.LazyExoticComponent<React.ComponentType> {
  return lazy(() => retry(component, retries, interval));
}

function retry(
  fn: () => ComponentPromise,
  retriesLeft = 3,
  interval = 1000,
): ComponentPromise {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            window.location.reload(); // Think this fixes chunk loading error
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function handleTocScroll(): void {
  const elem = document.getElementById('toc');
  if (
    elem &&
    document.body.clientWidth >= 768 &&
    window.screen.availHeight - 100 > elem.clientHeight
  ) {
    const parent = elem.offsetParent;
    let offset = 0;
    if (parent) {
      offset = (parent as HTMLElement).offsetTop + 100;
    }
    elem.style.transform = `translateY(${Math.max(
      0,
      window.scrollY - offset,
    )}px)`;
  }
}

export function toSpongebobCase(text: string): string {
  let j = 0;
  return text
    .split('')
    .map((letter) => {
      if (!letter.match(/[^A-Za-z]/g)) {
        j++;
      }
      return j % 2 ? letter.toUpperCase() : letter.toLowerCase();
    })
    .join('');
}

export function formatIntervalDate(
  interval: number,
  dailyInterval: number,
  language: LanguageISO,
): string {
  if (interval === dailyInterval) {
    return translate('everyDay', language);
  }
  if (interval < 0) {
    return convertIntervalToDates(interval)
      .map((date) => LocaleConfig.locales[language].dayNames[date.getDay()])
      .join(', ');
  }
  return `${convertIntervalToDates(interval)
    .map((date) => `${date.getDate().toString(10)}.`)
    .join(', ')} ${translate('ofMonth', language)}`;
}

export function inDateInterval(from: Date, to: Date, check: Date): boolean {
  from = new Date(from);
  from.setHours(0, 0, 0, 0);
  to = new Date(to);
  to.setHours(23, 59, 59, 999);
  check = new Date(check);
  if (from.getTime() > check.getTime()) {
    return false;
  }
  return to.getTime() >= check.getTime();
}

export function convertIntervalToDates(interval: number): Date[] {
  const now = new Date();
  const dates: Date[] = [];
  if (interval > 0) {
    for (let i = 0; i < 31; i++) {
      // eslint-disable-next-line no-bitwise
      if ((2 ** i) & interval) {
        now.setDate(i + 1);
        dates.push(new Date(now.getTime()));
      }
    }
  } else if (interval < 0) {
    interval *= -1;
    const temp = new Date();
    for (let i = 0; i < 7; i++) {
      // eslint-disable-next-line no-bitwise
      if ((2 ** i) & interval) {
        const curDay = temp.getDay();
        now.setDate(temp.getDate() + (i - curDay));
        dates.push(new Date(now.getTime()));
      }
    }
  }
  return dates;
}

export function convertDatesToInterval(dates: Date[], weekly = true): number {
  let interval = 0;
  dates.forEach((date) => {
    if (weekly) {
      interval += 2 ** date.getDay();
    } else {
      interval += 2 ** (date.getDate() - 1);
    }
  });
  return weekly ? -interval : interval;
}

export function alterInterval(
  interval: number,
  date: Date,
  weekly = true,
): number {
  const dates = convertIntervalToDates(interval);
  let newDate = true;
  if (weekly) {
    dates.forEach((selected, index) => {
      if (selected.getDay() === date.getDay()) {
        dates.splice(index, 1);
        newDate = false;
      }
    });
  } else {
    dates.forEach((selected, index) => {
      if (selected.getDate() === date.getDate()) {
        dates.splice(index, 1);
        newDate = false;
      }
    });
  }
  if (newDate) {
    dates.push(date);
  }
  return convertDatesToInterval(dates, weekly);
}

export function isAHoliday(check: Date, holidays: Holiday[]): boolean {
  for (let i = 0; i < holidays.length; i++) {
    if (inDateInterval(holidays[i].from, holidays[i].to, check)) {
      return true;
    }
  }
  return false;
}

export function isDeliverableDay(
  check: Date,
  holidays: Holiday[],
  bakery: BakeryUser,
): boolean {
  const weekday = new Date(check).getDay();
  if (weekday === 6 && !bakery.delivers_saturday) {
    return false;
  }
  if (weekday === 0 && !bakery.delivers_sunday) {
    return false;
  }
  return !isAHoliday(check, holidays);
}

export function arrayDiff<T extends string | boolean | number>(
  a: T[],
  b: T[],
): T[] {
  return a.filter((t) => !b.includes(t));
}

export function isIDType(test: CollectionType | IDType): test is IDType {
  return typeof test === 'string';
}

export function isNotJustId<T extends CollectionType>(
  test: JustID | T,
): test is T {
  return '__typename' in test;
}

export function arrayDiffObjectsMixed(
  a: CollectionType[],
  b: IDType[],
): CollectionType[] {
  return a.filter((t) => b.includes(t.id));
}

export function arrayDiffIdsMixed(a: IDType[], b: CollectionType[]): IDType[] {
  return a.filter((t) => !includesCustom(b, t));
}

export function includesCustom<T extends JustID>(
  array: T[],
  searchObj: T | IDType,
): boolean {
  return !!array.find((val) =>
    typeof searchObj === 'string'
      ? val.id === searchObj
      : val.id === searchObj.id,
  );
}

export function displayImage(
  media: StrapiMedia | string,
  language: LanguageISO,
  thumbnail = false,
  className = '',
): JSX.Element {
  if (typeof media === 'string')
    return (
      <img
        alt={baseURL() + media}
        title={media}
        src={media}
        className={className}
      />
    );
  try {
    const formats: MediaFormats = JSON.parse(media.formats);
    const caption: Keyword = JSON.parse(media.caption);
    const alternativeText: Keyword = JSON.parse(media.alternativeText);
    const srcSet =
      `${baseURL() + formats.xlarge.url} ${formats.xlarge.width}w, ` +
      `${baseURL() + formats.large.url} ${formats.large.width}w, ` +
      `${baseURL() + formats.medium.url} ${formats.medium.width}w, ` +
      `${baseURL() + formats.small.url} ${formats.small.width}w, ` +
      `${baseURL() + formats.xsmall.url} ${formats.xsmall.width}w, ` +
      `${baseURL() + formats.thumbnail.url} ${formats.thumbnail.width}w, `;
    return (
      <img
        alt={alternativeText[language]}
        title={caption[language]}
        srcSet={srcSet}
        src={
          thumbnail ? baseURL() + formats.thumbnail.url : baseURL() + media.url
        }
        className={className}
      />
    );
  } catch (e) {
    if (media.provider === 'local') {
      let alternativeText: Keyword = {
        de: media.alternativeText,
        it: media.alternativeText,
        en: media.alternativeText,
      };
      let caption: Keyword = {
        de: media.caption,
        it: media.caption,
        en: media.caption,
      };
      try {
        alternativeText = JSON.parse(media.alternativeText);
        caption = JSON.parse(media.caption);
      } catch (ee) {
        // TODO de hobn olle no kuan num
      }
      return (
        <img
          alt={alternativeText[language]}
          title={caption[language]}
          src={baseURL() + media.url}
          className={className}
        />
      );
    }
    return (
      <img
        alt={media.alternativeText}
        title={media.caption}
        src={media.url}
        className={className}
      />
    );
  }
}

export function triggerSubmitOnCtrlS<
  T extends React.Component & {
    submitBtn: HTMLInputElement | HTMLTextAreaElement | null;
  },
>(this: T, event: KeyboardEvent): void {
  const charCode = String.fromCharCode(event.which).toLowerCase();
  if ((event.ctrlKey || event.metaKey) && charCode === 's') {
    if ('submitBtn' in this && this.submitBtn) this.submitBtn.click();
  }
}

export function extractGuestMessage(message: string, guest: string): string {
  if (guest === '') {
    if (message.startsWith('|') || message.startsWith(' |'))
      return message.slice(2, message.length);
    return message;
  }
  const split = message.split(' | ');
  let retval = '';
  split.forEach((s) => {
    if (s.indexOf(':') === -1) return;
    const parts = s.split(':');
    if (parts[0].trim() === guest)
      retval =
        parts.length === 2 ? parts[1] : parts.slice(1, parts.length).join(':');
  });
  return retval;
}
