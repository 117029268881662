import React from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  AllowedUser,
  Credentials,
  LanguageISO,
  PriceList,
  User,
  Vat,
} from './types';

export const PROD_ENDPOINT = 'https://api.slowvita.it';
export const STAG_ENDPOINT = 'https://dev.api.slowvita.it';
export const DEV_ENDPOINT = 'http://localhost:1337';

export function baseURL(): string {
  const endpoint = window.localStorage.getItem('endpoint');
  if (
    endpoint &&
    (endpoint === PROD_ENDPOINT ||
      endpoint === STAG_ENDPOINT ||
      endpoint === DEV_ENDPOINT)
  )
    return endpoint;
  return PROD_ENDPOINT;
}
export function setEndpoint(endpoint: string): void {
  window.localStorage.setItem('endpoint', endpoint);
}
export function graphQlURL(): string {
  return `${baseURL()}/graphql`;
}
export const THEME_MAIN_COLOR = '#4c331f';
export const THEME_MAIN_WHITE = '#827062';
export const THEME_MAIN_COLOR_DARK = 'rgba(76,51,31,.8)';
export const THEME_SECONDARY_COLOR = '#bfb2a6';
export const THEME_SECONDARY_COLOR_DARK = 'rgba(191,178,166,.8)';
export const THEME_BG_COLOR = '#ffffff';
export const WEBSITE_URL = 'https://bestellung.sellemond.it';
export const APP_GOOGLE_URL =
  'https://play.google.com/store/apps/details?id=com.waldboth.sellemond';
export const APP_IOS_URL =
  'https://apps.apple.com/us/app/sellemond/id1584983651';
export const APP_NAME = 'Sellemond';
export const BAKERY: 'Sellemond' | 'SlowVita' | 'Bäckerei Frisch' = 'Sellemond';
export const URL = 'bestellung.sellemond.it';
export const SLOGAN = {
  de: 'Bäckerei, Konditorei und Cafè',
  it: 'Panificio, Pasticceria e Bar',
  en: 'Bakery, pastry shop and café',
};
export const LOGIN_IMAGE_DE = '/whatsSellemond1_de.png';
export const LOGIN_IMAGE_IT = '/whatsSellemond2_it.png';
export const LOGIN_IMAGE_EN = '/whatsSellemond3_en.png';
export const DASHBOARD_IMAGE_DE = '/useSellemond1_de.png';
export const DASHBOARD_IMAGE_IT = '/useSellemond2_it.png';
export const DASHBOARD_IMAGE_EN = '/useSellemond3_en.png';

export enum MESSAGE_TYPE {
  INFO,
  DANGER,
  MUTED,
  SUCCESS,
}

const userContext: {
  user: AllowedUser | null;
  client: null | ApolloClient<NormalizedCacheObject>;
  logout: () => void;
  updateUserResponse: (user: AllowedUser) => void;
  language: LanguageISO;
  vats: Vat[];
  changePageLanguage: (lang: LanguageISO) => void;
  loginFunc: (creds: Credentials | null) => void;
  authMessage: string;
  authMessageType: MESSAGE_TYPE;
  identifier: string;
  password: string;
  numNewPrivates: number;
  decrNumNewPrivates: () => void;
  userPriceLists: PriceList[];
} = {
  user: null,
  client: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateUserResponse: () => {},
  language: 'de',
  vats: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changePageLanguage: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loginFunc: () => {},
  authMessage: '',
  authMessageType: MESSAGE_TYPE.MUTED,
  identifier: '',
  password: '',
  numNewPrivates: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  decrNumNewPrivates: () => {},
  userPriceLists: [],
};

export const UserContext = React.createContext(userContext);
export const UserContextLoggedIn = React.createContext(
  userContext as UserContextTypeLoggedIn,
);

export declare type UserContextTypeLoggedIn = {
  user: AllowedUser;
  client: ApolloClient<NormalizedCacheObject>;
  logout: () => void;
  updateUserResponse: (user: AllowedUser) => void;
  language: LanguageISO;
  vats: Vat[];
  changePageLanguage: (lang: LanguageISO) => void;
  loginFunc: (creds: Credentials | null) => void;
  authMessage: string;
  authMessageType: MESSAGE_TYPE;
  identifier: string;
  password: string;
  numNewPrivates: number;
  decrNumNewPrivates: () => void;
  userPriceLists: PriceList[];
};
export declare type UserContextTypeLoggedOut = {
  user: User | null;
  client: ApolloClient<NormalizedCacheObject>;
  logout: () => void;
  updateUserResponse: (user: User) => void;
  language: LanguageISO;
  vats: Vat[];
  changePageLanguage: (lang: LanguageISO) => void;
  loginFunc: (creds: Credentials | null) => void;
  authMessage: string;
  authMessageType: MESSAGE_TYPE;
  identifier: string;
  password: string;
  numNewPrivates: 0;
};

export const DAY_IN_MS = 864e5;
export const CENTURY_IN_MS = 3187296000000;
