import { gql } from '@apollo/client';
import {
  ABO_FRAGMENT,
  BILL_FRAGMENT,
  HOLIDAY_FRAGMENT,
  HOST_PRODUCT_FRAGMENT,
  LANGUAGE_FRAGMENT,
  MESSAGE_FRAGMENT,
  ORDER_FRAGMENT,
  ORDER_FRAGMENT_BAKERY_SMALL,
  ORDER_FRAGMENT_DASHBOARD,
  ORDER_FRAGMENT_FULL,
  PLAN_FRAGMENT,
  PRICE_LIST_FRAGMENT,
  PRODUCT_FRAGMENT,
  TRANSLATION_FRAGMENT,
  USER_FRAGMENT,
  USER_FRAGMENT_DASHBOARD,
  VAT_FRAGMENT,
  ZONE_FRAGMENT,
} from './fragments';

/// /////////////////////////////////////////////////
/// / PRODUCTS
/// /////////////////////////////////////////////////
export const GET_BAKERY_PRODUCTS = gql`
  query GetProducts($bakeryID: ID!, $offset: Int!, $limit: Int!) {
    products(
      filters: {
        bakery: { id: { eq: $bakeryID } }
        deleted: { eq: false }
        active: { eq: true }
      }
      pagination: { limit: $limit, start: $offset }
      sort: "display_order:asc"
    ) {
      data {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_BAKERY_PRODUCT_BY_PRICELIST = gql`
  query GetProducts(
    $bakeryID: ID!
    $offset: Int!
    $limit: Int!
    $priceLists: [ID]!
  ) {
    products(
      filters: {
        bakery: { id: { eq: $bakeryID } }
        deleted: { eq: false }
        active: { eq: true }
        price_lists: { price_list: { id: { in: $priceLists } } }
      }
      pagination: { limit: $limit, start: $offset }
      sort: "display_order:asc"
    ) {
      data {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_BAKERY_PRODUCT = gql`
  query GetProduct($productID: ID!) {
    product(id: $productID) {
      data {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;
export const GET_HOST_PRODUCT = gql`
  query GetHostProduct($productID: ID!) {
    hostProduct(id: $productID) {
      data {
        ...HostProductFragment
      }
    }
  }
  ${HOST_PRODUCT_FRAGMENT}
`;

export const GET_HOST_PRODUCT_BY_BASE = gql`
  query GetHostProduct($baseProductID: ID!, $hostID: ID!) {
    hostProducts(
      filters: {
        host: { id: { eq: $hostID } }
        base_product: { id: { eq: $baseProductID } }
      }
    ) {
      data {
        ...HostProductFragment
      }
    }
  }
  ${HOST_PRODUCT_FRAGMENT}
`;

export const GET_HOST_PRODUCTS = gql`
  query GetProductsOfHost($hostID: ID!, $offset: Int!, $limit: Int!) {
    hostProducts(
      filters: {
        host: { id: { eq: $hostID } }
        base_product: { deleted: { eq: false } }
      }
      pagination: { limit: $limit, start: $offset }
      sort: "base_product.display_order:asc"
    ) {
      data {
        ...HostProductFragment
      }
    }
  }
  ${HOST_PRODUCT_FRAGMENT}
`;

export const HOST_PRODUCTS_COUNT = gql`
  query CountHostProducts($hostID: ID!) {
    hostProducts(
      filters: {
        host: { id: { eq: $hostID } }
        base_product: { deleted: { eq: false } }
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const SEARCH_EASYBACK_PRODUCT = gql`
  query SearchEasyBackProducts($easyBackCodec: [String]!, $userID: ID!) {
    products(
      filters: {
        easyback_kodex: { in: $easyBackCodec }
        bakery: { id: { eq: $userID } }
      }
    ) {
      data {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_ORDERED_PRODUCT_GUEST = gql`
  query OrderedProdGuest($guestID: ID!, $from: DateTime!, $to: DateTime!) {
    orderedProducts(
      filters: {
        ordered_by: { id: { eq: $guestID } }
        and: [{ createdAt: { gte: $from } }, { createdAt: { lte: $to } }]
      }
      pagination: { limit: 1000 }
      sort: "createdAt:asc"
    ) {
      data {
        id
        attributes {
          createdAt
          amount
          current_vat
          ordered_by {
            data {
              id
              attributes {
                username
              }
            }
          }
          current_gram_price
          current_approx_weight
          measured_weight
          current_display_price
          order {
            data {
              id
              attributes {
                createdAt
              }
            }
          }
          base_product {
            data {
              ...ProductFragment
            }
          }
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;
/// /////////////////////////////////////////////////
/// / USER
/// /////////////////////////////////////////////////

export const GET_USER = gql`
  ${USER_FRAGMENT}
  query getUser($userID: ID!) {
    usersPermissionsUser(id: $userID) {
      data {
        ...UserFragment
      }
    }
  }
`;
export const GET_NEW_PRIVATES = gql`
  ${USER_FRAGMENT}
  query getNewPrivatesOfBakery($bakeryID: ID!) {
    usersPermissionsUsers(
      filters: { bakery: { id: { eq: $bakeryID } }, blocked: { null: true } }
    ) {
      data {
        ...UserFragment
      }
    }
  }
`;
export const COUNT_NEW_PRIVATES = gql`
  query countNewPrivatesOfBakery($bakeryID: ID!) {
    usersPermissionsUsers(
      filters: { bakery: { id: { eq: $bakeryID } }, blocked: { null: true } }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
export const GET_HOSTS_OF_BAKERY = gql`
  ${USER_FRAGMENT}
  query getUsersOfBakery(
    $userID: ID!
    $limit: Int!
    $offset: Int!
    $blocked: Boolean = false
  ) {
    usersPermissionsUsers(
      filters: { bakery: { id: { eq: $userID } }, blocked: { eq: $blocked } }
      pagination: { limit: $limit, start: $offset }
      sort: "id:desc"
    ) {
      data {
        ...UserFragment
      }
    }
  }
`;
export const GET_CLIENTS_OF_HOST = gql`
  ${USER_FRAGMENT}
  query getUsersOfHost(
    $userID: ID!
    $limit: Int!
    $offset: Int!
    $blocked: Boolean = false
  ) {
    usersPermissionsUsers(
      filters: { host: { id: { eq: $userID } }, blocked: { eq: $blocked } }
      pagination: { limit: $limit, start: $offset }
      sort: "id:desc"
    ) {
      data {
        ...UserFragment
      }
    }
  }
`;
export const GET_ACTIVE_GUESTS_OF_HOST = gql`
  ${USER_FRAGMENT}
  query getActiveUsersOfHost($userID: ID!, $now: Date!) {
    usersPermissionsUsers(
      filters: {
        host: { id: { eq: $userID } }
        active_from: { lte: $now }
        active_to: { gte: $now }
        blocked: { eq: false }
      }
      pagination: { limit: 1000 }
    ) {
      data {
        ...UserFragment
      }
    }
  }
`;
export const GET_SUPPORT = gql`
  ${USER_FRAGMENT}
  {
    usersPermissionsUser(id: 67) {
      data {
        ...UserFragment
      }
    }
  }
`;

export const CHECK_EMAIL = gql`
  query checkMail($email: String!) {
    usersPermissionsUsers(filters: { email: { eq: $email } }) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
export const CHECK_USERNAME = gql`
  query checkUsername($username: String!) {
    usersPermissionsUsers(filters: { username: { eq: $username } }) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
export const GET_PLANS = gql`
  ${PLAN_FRAGMENT}
  {
    plans {
      data {
        ...PlanFragment
      }
    }
  }
`;

/// /////////////////////////////////////////////////
/// / ORDER
/// /////////////////////////////////////////////////
export const GET_ORDERS_OF_HOST = gql`
  query GetOrderOfHost($hostID: ID!, $offset: Int = 0, $limit: Int = 10) {
    orders(
      filters: { host: { id: { eq: $hostID } } }
      pagination: { limit: $limit, start: $offset }
      sort: "createdAt:desc"
    ) {
      data {
        ...OrderFragmentBakerySmall
      }
    }
  }
  ${ORDER_FRAGMENT_BAKERY_SMALL}
`;

export const GET_ORDERS_OF_BAKERY = gql`
  query GetOrderOfBakery($bakeryID: ID!, $offset: Int = 0, $limit: Int = 10) {
    orders(
      filters: {
        host: { bakery: { id: { eq: $bakeryID } } }
        accepted: { eq: true }
      }
      pagination: { limit: $limit, start: $offset }
      sort: "createdAt:desc"
    ) {
      data {
        ...OrderFragmentBakerySmall
      }
    }
  }
  ${ORDER_FRAGMENT_BAKERY_SMALL}
`;

export const GET_MAX_PRODUCT_DISPLAY_ORDER = gql`
  query maxDisplayOrder($bakeryID: ID!) {
    products(
      filters: { bakery: { id: { eq: $bakeryID } } }
      sort: "display_order:desc"
      pagination: { limit: 1, start: 0 }
    ) {
      data {
        id
        attributes {
          display_order
        }
      }
    }
  }
`;

export const FIND_ORDERS_OF_BAKERY = gql`
  query FindOrdersOfBakery($bakeryID: ID!, $publicID: String!) {
    orders(
      filters: {
        host: { bakery: { id: { eq: $bakeryID } } }
        public_id: { contains: $publicID }
      }
      pagination: { limit: 10, start: 0 }
      sort: "createdAt:desc"
    ) {
      data {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`;
export const GET_ORDERS_OF_BAKERY_FOR_CALCULATION = gql`
  query GetOrderOfBakery($bakeryID: ID!, $from: DateTime!, $to: DateTime!) {
    orders(
      filters: {
        host: { bakery: { id: { eq: $bakeryID } } }
        deadline: { gt: $from }
        deadline: { lt: $to }
        accepted: { eq: true }
      }
      pagination: { limit: 10000 }
    ) {
      data {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`;
export const GET_ORDERS_OF_BAKERY_TO_COMPILE = gql`
  query GetOrderCompile($bakeryID: ID!, $after: DateTime!, $zone: ID) {
    orders(
      filters: {
        host: {
          bakery: { id: { eq: $bakeryID } }
          delivery_zone: { id: { eq: $zone } }
        }
        deadline: { eq: $after }
        accepted: { eq: true }
      }
      pagination: { limit: 1000 }
    ) {
      data {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_ORDER = gql`
  query GetOrder($orderID: ID!) {
    order(id: $orderID) {
      data {
        ...OrderFragmentFull
      }
    }
  }
  ${ORDER_FRAGMENT_FULL}
`;

export const ORDERS_COUNT = gql`
  {
    orders {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const ORDERS_COUNT_HOST = gql`
  query CountOrdersOfHost($userID: ID!) {
    orders(filters: { host: { id: { eq: $userID } } }) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
export const ORDERS_COUNT_USER = gql`
  query CountOrdersOfUser($userID: ID!) {
    orders(
      filters: { ordered_products: { ordered_by: { id: { eq: $userID } } } }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_BILLABLE_GUESTS = gql`
  query getBillableGuests($hostID: ID!) {
    usersPermissionsUsers(
      filters: {
        host: { id: { eq: $hostID } }
        ordered_products: { id: { gt: 0 } }
        blocked: { eq: false }
      }
      pagination: { limit: 1000 }
    ) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / LANGUAGE
/// /////////////////////////////////////////////////

export const GET_LANGUAGES = gql`
  {
    languages {
      data {
        ...LanguageFragment
      }
    }
  }
  ${LANGUAGE_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / VAT
/// /////////////////////////////////////////////////

export const GET_VATS = gql`
  {
    vats {
      data {
        ...VatFragment
      }
    }
  }
  ${VAT_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / BILL
/// /////////////////////////////////////////////////

export const GET_BILLABLE_HOSTS_PRIVATES = gql`
  query getBillableHostsPrivates(
    $bakeryID: ID!
    $from: DateTime!
    $to: DateTime!
  ) {
    orders(
      filters: {
        host: { bakery: { id: { eq: $bakeryID } } }
        and: [{ deadline: { lt: $to } }, { deadline: { gt: $from } }]
        bill: { id: { null: true } }
      }
      pagination: { limit: 1000 }
    ) {
      data {
        attributes {
          bill {
            data {
              id
            }
          }
          host {
            data {
              id
              attributes {
                username
                userdetail {
                  data {
                    id
                    attributes {
                      company_name
                      full_name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_BILLABLE_ORDERS = gql`
  query getBillableOrders($userID: ID!, $from: DateTime!, $to: DateTime!) {
    orders(
      filters: {
        host: { id: { eq: $userID } }
        and: [{ deadline: { gt: $from } }, { deadline: { lt: $to } }]
      }
      pagination: { limit: 10000 }
    ) {
      data {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_BILLS_OF_HOST = gql`
  query getBillsOfHost($userID: ID!, $offset: Int = 0) {
    bills(
      filters: { client: { id: { eq: $userID } } }
      pagination: { limit: 10, start: $offset }
      sort: "createdAt:desc"
    ) {
      data {
        ...BillFragment
      }
    }
  }
  ${BILL_FRAGMENT}
`;
export const GET_BILLS_OF_BAKERY = gql`
  query getBillsOfBakery($userID: ID!, $offset: Int = 0) {
    bills(
      filters: { bakery: { id: { eq: $userID } } }
      pagination: { limit: 10, start: $offset }
      sort: "createdAt:desc"
    ) {
      data {
        ...BillFragment
      }
    }
  }
  ${BILL_FRAGMENT}
`;

export const GET_BILL = gql`
  query getBill($billID: ID!) {
    bill(id: $billID) {
      data {
        ...BillFragment
      }
    }
  }
  ${BILL_FRAGMENT}
`;

export const ORDERS_OF_BILL = gql`
  query ordersOfBill($billID: ID!) {
    orders(filters: { bill: { id: { eq: $billID } } }) {
      data {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / ASIDE
/// /////////////////////////////////////////////////

export const GET_NEWSFEED_HOST = gql`
  query getNewsfeedHost($userID: ID!) {
    usersPermissionsUsers(
      filters: { host: { id: { eq: $userID } } }
      pagination: { limit: 5 }
      sort: "createdAt:desc"
    ) {
      data {
        ...UserFragment
      }
    }
    bills(
      filters: { orders: { host: { id: { eq: $userID } } } }
      pagination: { limit: 5 }
      sort: "createdAt:desc"
    ) {
      data {
        ...BillFragment
      }
    }
    hostProducts(
      filters: { host: { id: { eq: $userID } } }
      pagination: { limit: 5 }
      sort: "createdAt:desc"
    ) {
      data {
        ...HostProductFragment
      }
    }
    orders(
      filters: { host: { id: { eq: $userID } } }
      pagination: { limit: 5 }
      sort: "createdAt:desc"
    ) {
      data {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
  ${HOST_PRODUCT_FRAGMENT}
  ${BILL_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const GET_NEWSFEED_BAKERY = gql`
  query getNewsfeedBakery($userID: ID!) {
    usersPermissionsUsers(
      filters: { bakery: { id: { eq: $userID } } }
      pagination: { limit: 5 }
      sort: "createdAt:desc"
    ) {
      data {
        ...UserFragment
      }
    }
    bills(
      filters: { orders: { host: { bakery: { id: { eq: $userID } } } } }
      pagination: { limit: 5 }
      sort: "createdAt:desc"
    ) {
      data {
        ...BillFragment
      }
    }
    products(
      filters: { bakery: { id: { eq: $userID } } }
      pagination: { limit: 5 }
      sort: "createdAt:desc"
    ) {
      data {
        ...ProductFragment
      }
    }
    orders(
      filters: { host: { bakery: { id: { eq: $userID } } } }
      pagination: { limit: 5 }
      sort: "createdAt:desc"
    ) {
      data {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${BILL_FRAGMENT}
  ${USER_FRAGMENT}
`;

// WARN use state_null bcs of issue https://github.com/strapi/strapi/issues/5414 use reply_to_null:true then
export const GET_CONVERSATIONS = gql`
  query getConversations($userID: ID!, $offset: Int = 0) {
    messages(
      filters: {
        or: [
          { writer: { id: { eq: $userID } } }
          { recipients: { id: { eq: $userID } } }
        ]
        reply_to: { id: { null: true } }
        state: { null: false }
      }
      pagination: { start: $offset, limit: 10 }
      sort: "createdAt:desc"
    ) {
      data {
        ...MessageFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;
export const GET_MESSAGES = gql`
  query getMessages($userID: ID!, $replyTo: ID!) {
    messages(
      filters: {
        or: [
          { writer: { id: { eq: $userID } } }
          { recipients: { id: { eq: $userID } } }
        ]
        reply_to: { id: { eq: $replyTo } }
      }
      sort: "createdAt:asc"
    ) {
      data {
        ...MessageFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;
export const GET_MESSAGE = gql`
  query getMessage($messageID: ID!) {
    message(id: $messageID) {
      data {
        ...MessageFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / DASHBOARD
/// /////////////////////////////////////////////////

export const DASHBOARD_BAKERY = gql`
  query getDashboardBakery($step: Int!) {
    dashboardData(input: { step: $step }) {
      sales {
        sales {
          sales
          delivered
          paid
        }
        from
        date
      }
      clients {
        date
        amount
      }
      orders {
        date
        amount
      }
      products {
        date
        amount
      }
    }
  }
`;

export const DASHBOARD_BAKERY_CONT = gql`
  query getDashboardBakeryCont($userID: ID!) {
    orders(
      filters: { host: { bakery: { id: { eq: $userID } } } }
      sort: "createdAt:desc"
      pagination: { limit: 10 }
    ) {
      data {
        ...OrderFragmentDashboard
      }
    }
    usersPermissionsUsers(
      filters: { is_host: { eq: true }, bakery: { id: { eq: $userID } } }
      sort: "createdAt:desc"
      pagination: { limit: 10 }
    ) {
      data {
        ...UserFragmentDashboard
      }
    }
  }
  ${ORDER_FRAGMENT_DASHBOARD}
  ${USER_FRAGMENT_DASHBOARD}
`;

// TODO rework
export const GET_BAKELIST = gql`
  query getBakeList($bakeryID: ID!, $deadline: DateTime!) {
    orderedProducts(
      filters: {
        order: {
          host: { bakery: { id: { eq: $bakeryID } } }
          deadline: { eq: $deadline }
          accepted: { eq: true }
        }
      }
      pagination: { limit: 10000 }
    ) {
      data {
        id
        attributes {
          amount
          base_product {
            data {
              id
              attributes {
                name {
                  data {
                    ...TranslationFragment
                  }
                }
              }
            }
          }
        }
      }

      meta {
        pagination {
          total
        }
      }
    }
  }
  ${TRANSLATION_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / DELIVERY ZONE
/// /////////////////////////////////////////////////

export const GET_DELIVERY_ZONES_BY_USER = gql`
  query getDeliveryZonesByUser(
    $userID: ID!
    $getOrders: Boolean!
    $getUsers: Boolean!
    $deadline: DateTime
  ) {
    deliveryZones(
      filters: { bakery: { id: { eq: $userID } } }
      pagination: { limit: 1000 }
    ) {
      data {
        ...ZoneFragment
      }
    }
    usersPermissionsUsers(
      filters: { bakery: { id: { eq: $userID } }, blocked: { eq: false } }
      pagination: { limit: 1000 }
    ) {
      data @include(if: $getUsers) {
        id
        attributes {
          delivery_zone {
            data {
              id
            }
          }
        }
      }
    }

    orders(
      filters: {
        host: { bakery: { id: { eq: $userID } } }
        deadline: { eq: $deadline }
        accepted: { eq: true }
      }
      pagination: { limit: 1000 }
    ) {
      data @include(if: $getOrders) {
        id
        attributes {
          host {
            data {
              id
              attributes {
                delivery_zone {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${ZONE_FRAGMENT}
`;

export const GET_DELIVERY_ZONE = gql`
  query getDeliveryZone($zoneID: ID!) {
    deliveryZone(id: $zoneID) {
      data {
        ...ZoneFragment
      }
    }
  }
  ${ZONE_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / HOLIDAY
/// /////////////////////////////////////////////////

export const GET_ACTIVE_HOLIDAY_OF_BAKERY = gql`
  query getActiveHolidayOfBakery($bakeryID: ID!, $now: Date!) {
    holidays(
      filters: {
        bakery: { id: { eq: $bakeryID } }
        from: { lt: $now }
        to: { gt: $now }
      }
    ) {
      data {
        ...HolidayFragment
      }
    }
  }
  ${HOLIDAY_FRAGMENT}
`;

export const GET_HOLIDAYS_OF_BAKERY = gql`
  query getDeliveryZone($bakeryID: ID!, $offset: Int!) {
    holidays(
      filters: { bakery: { id: { eq: $bakeryID } } }
      sort: "from:desc"
      pagination: { start: $offset, limit: 5 }
    ) {
      data {
        ...HolidayFragment
      }
    }
  }
  ${HOLIDAY_FRAGMENT}
`;

export const GET_HOLIDAY = gql`
  query getDeliveryZone($holidayID: ID!) {
    holiday(id: $holidayID) {
      data {
        ...HolidayFragment
      }
    }
  }
  ${HOLIDAY_FRAGMENT}
`;

export const FIND_GUEST = gql`
  query findGuest($username: String!) {
    usersPermissionsUsers(filters: { username: { contains: $username } }) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / ABO
/// /////////////////////////////////////////////////

export const GET_ABOS_HOST = gql`
  query GetAbosBakery($userID: ID!, $offset: Int = 0, $limit: Int = 10) {
    abos(
      filters: { user: { id: { eq: $userID } } }
      pagination: { limit: $limit, start: $offset }
      sort: "createdAt:desc"
    ) {
      data {
        ...AboFragment
      }
    }
  }
  ${ABO_FRAGMENT}
`;

export const GET_ABOS_HOST_GUESTS = gql`
  query GetAbosBakery($userID: ID!, $offset: Int = 0, $limit: Int = 10) {
    abos(
      filters: { user: { host: { id: { eq: $userID } } } }
      pagination: { limit: $limit, start: $offset }
      sort: "createdAt:desc"
    ) {
      data {
        ...AboFragment
      }
    }
  }
  ${ABO_FRAGMENT}
`;

export const GET_ABOS_BAKERY = gql`
  query GetAbosBakery($userID: ID!, $offset: Int = 0, $limit: Int = 10) {
    abos(
      filters: { user: { bakery: { id: { eq: $userID } } } }
      pagination: { limit: $limit, start: $offset }
      sort: "createdAt:desc"
    ) {
      data {
        ...AboFragment
      }
    }
  }
  ${ABO_FRAGMENT}
`;
export const GET_ABOS_BAKERY_GUESTS = gql`
  query GetAbosBakery($userID: ID!, $offset: Int = 0, $limit: Int = 10) {
    abos(
      filters: { user: { host: { bakery: { id: { eq: $userID } } } } }
      pagination: { limit: $limit, start: $offset }
      sort: "createdAt:desc"
    ) {
      data {
        ...AboFragment
      }
    }
  }
  ${ABO_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / User
/// /////////////////////////////////////////////////

export const USERS = gql`
  {
    usersPermissionsUsers(filters: { takeaway: { null: true } }) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;
export const BAKERIES = gql`
  {
    usersPermissionsUsers(filters: { is_bakery: { eq: true } }) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / GET PriceList
/// /////////////////////////////////////////////////

export const GET_PRICELISTS = gql`
  query getPriceLists($bakery: ID!) {
    priceLists(
      filters: { bakery: { id: { eq: $bakery } } }
      pagination: { limit: 1000 }
    ) {
      data {
        ...PriceListFragment
      }
    }
  }
  ${PRICE_LIST_FRAGMENT}
`;
